import React from 'react';
import { ThemeProvider, createTheme, SoundsProvider, createSounds, Arwes } from 'arwes';
import { darken, lighten } from 'polished';
import '../styles/index.css';

const soundCfg = {
    shared: { volume: 1, },  // Shared sound settings
    players: {  // The player settings
        click: {  // With the name the player is created
            sound: { src: ['/sounds/click.mp3'] }  // The settings to pass to Howler
        },
        typing: {
            sound: { src: ['/sounds/typing.mp3'] },
            settings: { oneAtATime: true }  // The custom app settings
        },
        deploy: {
            sound: { src: ['/sounds/deploy.mp3'] },
            settings: { oneAtATime: true }
        },
    }
};

const accent = 0.2;
const generateColor = (color: string) => ({
    base: color,
    light: lighten(accent, color),
    dark: darken(accent, color),
});
const generateBackground = (color: string) => ({
    level0: color,
    level1: lighten(0.015, color),
    level2: lighten(0.030, color),
    level3: lighten(0.045, color),
});

const themeCfg = {
    // Every color has a `base`, `light` and `dark` variation.
    color: {
        primary: generateColor('#c45cd6'),
        secondary: generateColor('#df9527'),
        header: generateColor('#c45cd6'),
        control: generateColor('#c45cd6'),
        success: generateColor('#00ff00'),
        alert: generateColor('#ad327e'),
        disabled: generateColor('#999999'),
    },

    // Every background color has level colors from 0 until 3
    // as `level0`, `level1`...
    background: {
        primary: generateBackground('#2a2033'),
        secondary: generateBackground('#180f02'),
        header: generateBackground('#032026'),
        control: generateBackground('#531f6b'),
        success: generateBackground('#081402'),
        alert: generateBackground('#140202'),
        disabled: generateBackground('#141414'),
    },
};

const style = {
    maxWidth: 600,
    width: "100%",
    minHeight: "100%",
    margin: "0 auto",
    padding: "2rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as const
};

const Layout = ({ children }: { children: boolean | undefined | React.ReactChild | (React.ReactChild | boolean | undefined)[] }) => (
    <ThemeProvider theme={createTheme(themeCfg)}>
        <SoundsProvider sounds={createSounds(soundCfg)}>
            <Arwes animate background="/bg.jpg">
                <div style={style}>
                    {children}
                </div>
            </Arwes>
        </SoundsProvider>
    </ThemeProvider>
);

export default Layout;